const get = require('lodash/get');

function getConnectionInfo(navigator={}) {
	const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || false;

	if (!connection) {
		return false;
	}

	return {
		downlink: `~${connection.downlink}mbps`,
		rtt: `~${connection.rtt}ms`,
		type: connection.type || 'N/A',
	};
}

function fetchNavigatorGeolocation(navigator = {}, onSuccess, onFailure) {
	const onLocationSuccess = (position) => {
		const latitude = get(position, 'coords.latitude');
		const longitude = get(position, 'coords.longitude');
		if (latitude && longitude) {
			const location = {
				latitude,
				longitude,
			};

			onSuccess(location);
		} else {
			onFailure();
		}
	};

	if (get(navigator, 'geolocation.getCurrentPosition')) {
		navigator.geolocation.getCurrentPosition(onLocationSuccess, onFailure);
	}
}

function areCookiesEnabled(document={}) {
	const testValue = 'iamatestcookietotestcookies';
	let cookiesEnabled = false;

	if (!document.cookie) {
		return false;
	}

	document.cookie = testValue;

	if (document.cookie.indexOf(testValue) >= 0) {
		cookiesEnabled = true;
	}

	// Remove it
	document.cookie = testValue + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

	return cookiesEnabled;
}

function getScreenInfo(window={}) {
	const screen = window.screen;

	if (!screen) {
		return false;
	}

	return {
		width: screen.width,
		height: screen.height,
		devicePixelRatio: window.devicePixelRatio || 'N/A',
	};
}

function getViewPortInfo(window={}) {
	const document = window.document;

	return {
		width: window.innerWidth || document.documentElement.clientWidth || 'N/A',
		height: window.innerHeight || document.documentElement.clientHeight || 'N/A',
	};
}

function getPluginInfo(navigator={}) {
	const plugins = navigator.plugins || [];

	if (!plugins || !Array.isArray(plugins) || plugins.length === 0) {
		return false;
	}

	return plugins
		.filter(plugin => !!plugin.name)
		.map((plugin={}) => {
			return {
				name: plugin.name,
				version: plugin.version || 'N/A',
			};
		});
}

function getCartInfo(rp_app={}) {
	const cart = rp_app.cart || {};
	if (!cart) {
		return false;
	}

	return {
		itemCount: cart.item_count,
		subtotal: cart.subtotal,
	};
}

/**
 * This is a very basic browser info grab. There are more complete solutions out there, but I didn't want to import 1k+ lines of JS for something we only use as part of the feedback widget
 * @param {object} params
 * @param {object} params.window - DOM window object
 * @param {object} params.rp_app - Our global rp_app object of collectiveness
 * @returns {{ getBasicInfo:function, getPlugins:function, getRpInfo:function, fetchGeolocation:function }}
 */
module.exports = ({ window, rp_app }) => {
	const { document, navigator } = window;

	return {
		getBasicInfo() {
			return {
				connectionSpeed: getConnectionInfo(navigator) || 'N/A',
				cookiesEnabled: areCookiesEnabled(document) || 'N/A',
				language: navigator.language || 'N/A',
				languages: navigator.languages || 'N/A',
				localTime: (new Date()).toString(),
				oscpu: navigator.oscpu || 'N/A',
				platform: navigator.platform || 'N/A',
				referrer: document.referrer || 'N/A',
				screen: getScreenInfo(window) || 'N/A',
				userAgent: navigator.userAgent || 'N/A',
				viewPort: getViewPortInfo(window),
			};
		},

		getPlugins() {
			return getPluginInfo(navigator) || 'N/A';
		},

		getRpInfo() {
			return {
				cart: getCartInfo(rp_app) || 'N/A',
			};
		},

		fetchGeolocation(onSuccess, onFailure) {
			return fetchNavigatorGeolocation(navigator, onSuccess, onFailure);
		},
	};
};
