/* global window, document, rp$ */
const $onReady = require('public/js/lib/on-ready');
const getCarousel = () => import(/* webpackChunkName: "bs-carousel" */ 'bootstrap/js/dist/carousel.js');
const getCollapse = () => import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/dist/collapse.js');
const getPopover = () => import(/* webpackChunkName: "bs-popover" */ 'bootstrap/js/dist/popover.js');
const getModal = () => import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/dist/modal.js');
const getTab = () => import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/dist/tab.js');
const getDropdown = () => import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dist/dropdown.js');

const carouselSelector = '.carousel';
const collapseSelector ='[data-toggle="collapse"]';
const popoverSelector = '.popover-tooltip'; //Popover is required for the add-to-cart button
/* eslint-disable-next-line  no-unused-vars */
const modalSelector = '.modal, .add-to-cart, .car-selected-module'; //Modal is required for add-to-cart button
const tabSelector = '.nav-tabs';
const dropdownSelector = '.dropdown';

//This is just a dumb check, all we need is probably cause to load the bootstrap components
const loadBootstrapModule = (selector, func, callback = null) => {
	const selectorInDOM = !!document.querySelector(selector);
	if (selectorInDOM) {
		return func().then(() => {
			if (typeof callback === 'function') {
				callback();
			}
		});
	}
};

const handleAddBootstrapModules = function () {
	getModal(); //Modal is used on almost every page so just load it

	loadBootstrapModule(carouselSelector, getCarousel, () => {
		rp$(carouselSelector).carousel();
	});

	loadBootstrapModule(collapseSelector, getCollapse);
	loadBootstrapModule(tabSelector, getTab);

	if (!window.__IS_WEBSTORE_PLUGIN) {
		// Including bootstrap dropdown breaks DDC (EX: https://www.landroverchicago.com/parts-store.htm)
		loadBootstrapModule(dropdownSelector, getDropdown);
	}
	
	//Load standard popovers
	loadBootstrapModule(popoverSelector, getPopover, () => {
		rp$(popoverSelector).popover({
			trigger: 'hover',
			placement: 'bottom'
		});
	});
};


rp$.fn.popover = function (options) {
	getPopover().then(() => {
		this.popover(options);
	});
};

$onReady({rp$, window,
	label: 'get-bootstrap',
	fn: function () {
		handleAddBootstrapModules();
	},
});
