/* global location */

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { clearLocalStorage, getSessionUrls } from './paypal-utils';
import { ORIGINS } from './paypal-constants';
import request from './request';

const approveOrder = (callback) => {
	return (data, actions) => {
		const sessionUrls = getSessionUrls();
		const baseUrl = get(sessionUrls, 'returnUrl');
		//sb=1 indicates that this is originating from the paypal Smart Button Experience as opposed to the legacy EC experience
		let url = `${baseUrl}/${ORIGINS.paypal.checkout}?token=${data.orderID}&sb=1&source=cart`;

		if (data.gateway) {
			url = `${url}&gateway=${data.gateway}`;
		}
		
		//This call back can be used to show a loading spinner
		if (isFunction(callback)) {
			callback(true);
		}

		return request(url)
			.then((response) => {
				if (response.restart) {
					return actions.restart();
				} else if (response.error) {
					let code = 6004;
					rp_app.logger.error({
						message: `Error approving Order`,
						error: response.error,
					});
					// actions.redirect requires fully qualified url 
					const currentPath = `${location.protocol}//${location.host}${location.pathname}`;
					if (response.error === 'checkout-missing-shipping-method') {
						code = 6015;
					}
					return actions.redirect(`${currentPath}?code=${code}`);
				}
				//once the order is approved remove paypal local storage keys
				clearLocalStorage();

				if(response.redirected && response.url) {
					return actions.redirect(response.url);
				}
			});
	};
};

module.exports = approveOrder;
