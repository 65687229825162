/* global window, document */

const getDealerWarning = () => import(/* webpackChunkName: "get-dealer-warning" */ 'public/js/components/dealer-warning.js');

// A list of classes used to open the vehicle picker
const dealerWarningSelectors = [
	'.dealer-warning-link',
];

const handleDealerWarning = (event) => {
	event.preventDefault();
	const { currentTarget } = event;
	const href = currentTarget.getAttribute('href');

	//dynamically import the dealer warning class
	getDealerWarning().then((module) => {
		module.openDealerWarning({ redirect: href });
	});
};

window.dealerWarning = (opts = {}) => {
	getDealerWarning().then((module) => {
		module.openDealerWarning(opts);
	});
};

/* Call vehicle picker on select elements
.*-----------------------------------------*/
document.querySelectorAll(dealerWarningSelectors.join(', ')).forEach((elem) => {
	if(!elem.attachedDealerWarningEvent) {
		elem.addEventListener('click', handleDealerWarning);
		elem.attachedDealerWarningEvent = true;
	}
});