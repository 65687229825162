const BrowserInfo = require('public/js/lib/browser-info');

/**
 * @param {object} params.window - DOM window object
 * @returns {{ setBrowserLocation:function }}
 */
module.exports = ({ window }) => {
	const { document } = window;

	return {
		/* Set browser location (contains lat/long only) if ip-location not cached [Currently Lead-Gen Only]
		.*-----------------------------------------*/
		setBrowserLocation() {
			$ajax({
				dataType: 'json',
				url: '/ajax/ip-location/get',
				success: (response) => {
					const onGeolocationComplete = () => rp$(document).trigger('geolocationComplete');

					const locationIsCached = response !== null;
					if (locationIsCached) {
						onGeolocationComplete();
					} else {
						const onGeolocationSuccess = (location) => {
							if (location) {
								$ajax({
									method: 'POST',
									dataType: 'json',
									url: '/ajax/ip-location/update',
									data: {
										location,
									},
									success: onGeolocationComplete,
								});
							}
						};

						const onGeolocationFailure = onGeolocationComplete;

						const browserInfo = BrowserInfo({ window });
						return browserInfo.fetchGeolocation(onGeolocationSuccess, onGeolocationFailure);
					}
				},
			});
		},
	};
};
