/* global window, rp$ */
const $onReady = require('public/js/lib/on-ready');
const getCart = () => import(/* webpackChunkName: "get-cart" */ 'public/js/components/cart');
const addToCartSelector = '.add-to-cart.enabled';

const handleAddToCartModule = () => {
	getCart().then((module) => {
		module.attachAddToCartEvents(addToCartSelector);
	});
};

$onReady({rp$, window,
	label: 'get-cart',
	fn: function () {
		handleAddToCartModule();
	},
});
