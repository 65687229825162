const Logger = require('@revolutionparts/client-error');

const reporter = (data) => {
	if (!data) {
		return;
	}

	$ajax({
		type: 'POST',
		url: '/client-error',
		data,
	});
};

const logger = new Logger({reporter});

module.exports = logger;
