/* global rp$, window */
const SearchBox = {};

SearchBox.setupSearchBox = function() {
	let searchBox = rp$('form.search-box');
	if (searchBox.length > 0) {
		if (window.__IS_WEBSTORE_PLUGIN) {
			// Plugin already has hooks for form submits, which will get attached before this stuff gets to.
			// Attach to the form input/button elements instead so it happens before the plugin events.
			let searchInput = searchBox.find('input[name=search_str]');
			searchInput.on('keypress', function handleInputEnter(e) {
				if (e.which === 13 && rp$(this).val().length === 0) {
					return false;
				}
			});

			let searchSubmit = searchBox.find('button[type=submit]');
			/* eslint-disable-next-line  no-unused-vars */
			searchSubmit.on('click', function handleSubmitClick(e) {
				let parentForm = rp$(this).closest('form');
				if (parentForm.length > 0) {
					let searchInput = parentForm.find('input[name=search_str]');

					if(window.tracking.google.use_gtag_admind_analytics){
						window.rp_app.track.track_ec_general('web_search', {
							query:searchInput.val() 
						});
					}

					if (searchInput.length && searchInput.val().length === 0) {
						return false;
					}
				}
			});
		} else {
			searchBox.on('submit', function handleSearchBoxSubmit() {
				let searchInput = rp$(this).find('input[name=search_str]');

				if(window.tracking.google.use_gtag_admind_analytics){
					window.rp_app.track.track_ec_general('web_search', {
						query:searchInput.val() 
					});
				}

				if (searchInput.length && searchInput.val().length === 0) {
					return false;
				}
			});
		}
	}
};

module.exports = SearchBox;
