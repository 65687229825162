import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { setItem, getItem } from 'public/js/lib/local-storage';
import { PAYPAL_STORAGE_KEYS, DELIVERY_TYPE_FILTER, DEFAULT_CURRENCY_CODE } from './paypal-constants';
import { mapMethodToShippingOption, getSessionUrls } from './paypal-utils';
import request from './request';

const buildShippingMethodsUrl = ({ country_code, postal_code }, selectedShippingOption, addressChanged, optionChanged) => {
	const sessionUrls = getSessionUrls();
	const baseUrl = get(sessionUrls, 'shippingUrl');

	const params = [];
	if (addressChanged) {
		params.push(`postalCode=${postal_code}`);
		params.push(`country=${country_code}`);
	}

	if (selectedShippingOption && optionChanged) {
		params.push(`shippingId=${get(selectedShippingOption, 'id')}`);
		const shippingAmount = get(selectedShippingOption, 'amount.value') || get(selectedShippingOption, 'amount.currencyValue');
		params.push(`shippingAmount=${shippingAmount}`);
	}

	return get(params, 'length') ? `${baseUrl}?${params.join('&')}` : '';
};

const shippingUpdate = (data, actions) => {
	const shippingAddress = get(data, 'shipping_address', {});
	const cachedShippingAddress = getItem(PAYPAL_STORAGE_KEYS.address);
	const prevAddress = cachedShippingAddress ? JSON.parse(cachedShippingAddress) : {};
	const isNewAddress = isEmpty(prevAddress) || !isEqual(prevAddress.postal_code, shippingAddress.postal_code);

	const selectedOption = get(data, 'selected_shipping_option');
	const selectedShippingMethod = !isEmpty(selectedOption) ? selectedOption : null;
	const cachedShippingMethod = getItem(PAYPAL_STORAGE_KEYS.shippingMethod);
	const prevShippingMethod = cachedShippingMethod ? JSON.parse(cachedShippingMethod) : {};
	const isNewSelectedShippingMethod = isEmpty(prevShippingMethod) || !isEqual(prevShippingMethod, selectedShippingMethod);

	const shippingId = getItem(PAYPAL_STORAGE_KEYS.shippingMethodId);
	const isNewShippingId = isEmpty(shippingId) || shippingId !== selectedShippingMethod.id;

	const currencyCode = get(data, 'amount.currency_code') || DEFAULT_CURRENCY_CODE;

	const errData = {
		orderID: get(data, 'orderID', 0),
		countryCode: get(shippingAddress, 'country_code', ''),
		postalCode: get(shippingAddress, 'postal_code', ''),
		prevShippingMethodId: get(prevShippingMethod, 'id'),
		selectedShippingMethodId: get(selectedShippingMethod, 'id'),
	};

	//If the address has been updated save the new address to local storage
	if (shippingAddress && isNewAddress) {
		setItem(PAYPAL_STORAGE_KEYS.address, JSON.stringify(shippingAddress));
	}

	if (selectedShippingMethod && isNewSelectedShippingMethod) {
		setItem(PAYPAL_STORAGE_KEYS.shippingMethod, JSON.stringify(selectedShippingMethod));
	}

	const url = buildShippingMethodsUrl(shippingAddress, selectedShippingMethod, isNewAddress, (isNewShippingId && isNewSelectedShippingMethod));

	if(url) {
		rp_app.logger.info({
			message: `paypal-smart-button Requesting shipping methods: ${JSON.stringify(errData)}`,
		});

		return request(url)
			.then((response) => {
				const methods = get(response, 'shippingMethods.methods', []);
				const shippingId = get(response, 'order.shippingId');
				setItem(PAYPAL_STORAGE_KEYS.shippingMethodId, shippingId);

				//Filter out pickup options for now, Format shipping methods to paypal obj
				const shippingOptions = sortShippingMethods(methods)
					//.filter(method => !DELIVERY_TYPE_FILTER.includes(method.deliveryType))
					.map((method, index) => mapMethodToShippingOption(method, index, shippingId, currencyCode));

				const shippingAmount = get(response, 'order.shippingAmount');
				const subtotalAmount = get(response, 'order.subtotalAmount');
				const taxAmount = get(response, 'order.taxAmount');
				const handlingAmount = get(response, 'order.handlingAmount');
				const totalAmount = get(response, 'order.totalAmount');
				const discountAmount = get(response, 'order.discountAmount');
				const deliveryFeeAmount = get(response, 'order.deliveryFeeAmount');

				const patchData = [
					{
						op: 'replace',
						path: '/purchase_units/@reference_id==\'default\'/amount',
						value: {
							currency_code: currencyCode,
							value: totalAmount,
							breakdown: {
								item_total: {
									currency_code: currencyCode,
									value: subtotalAmount
								},
								tax_total: {
									currency_code: currencyCode,
									value: taxAmount
								},
								shipping: {
									currency_code: currencyCode,
									value: shippingAmount
								},
								handling: {
									currency_code: currencyCode,
									value: handlingAmount
								},
								discount: {
									currency_code: currencyCode,
									value: discountAmount,
								},
								delivery_fee: {
									currency_code: currencyCode,
									value: deliveryFeeAmount,
								},
							}
						}
					},
				];

				if (shippingOptions && shippingOptions.length) {
					patchData.push(
						{
							op: isEmpty(selectedShippingMethod) ? 'add' : 'replace', // or 'add' if there are none.
							path: `/purchase_units/@reference_id=='default'/shipping/options`,
							value: shippingOptions
						}
					);
				}

				rp_app.logger.info({
					message: `paypal-smart-button Patching order data: ${JSON.stringify(errData)}`,
					patchData: JSON.stringify(patchData),
				});

				//Patch the paypal order with the new shipping options
				return actions.order.patch(patchData)
					.catch((err) => {
						rp_app.logger.error({
							message: `paypal-smart-button Failed to patch order data: ${JSON.stringify(errData)}`,
							error: err,
						});
						return actions.reject();
					});
			})
			.catch((err) => {
				rp_app.logger.error({
					message: `paypal-smart-button Failed to get shipping methods: ${JSON.stringify(errData)}`,
					error: err,
				});
				return actions.reject();
			});
	} else {
		return actions.resolve();
	}
};

function sortShippingMethods(methods) {
	return methods
		// Sort options by ascending amount
		.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount))
		// Move pre-defined non shipping items to end of array (Pickup)
		.filter(method => !DELIVERY_TYPE_FILTER.includes(method.deliveryType))
		.concat(methods.filter(method => DELIVERY_TYPE_FILTER.includes(method.deliveryType)));
}

module.exports = shippingUpdate;
