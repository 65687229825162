module.exports = {
	get: function (variable, url) {
		'use strict';
		var vars = this.all(url),
			item;

		for (item in vars) {
			if (item === variable) {
				return vars[item];
			}
		}
		return false;
	},
	all: function (url) {
		'use strict';
		var query = (url) ? url.split('?')[1] : rp_app.location.search.substring(1);
		return this.parse(query);
	},
	hash: function(hash) {
		'use strict';
		var query = (hash) ? hash.split('#')[1] : rp_app.location.hash.substring(1);
		return this.parse(query);
	},
	parse: function(query) {
		'use strict';
		var vars = (query) ? query.split('&') : [];
		var result = {}, pair, i;

		for (i = 0; i < vars.length; i++) {
			pair = vars[i].split('=');
			result[pair[0]] = pair[1];
		}

		return result;
	}
};
