const isString = require('lodash/isString');

/**
 * Check if given prop is a dom element (does it have an innerHtml)
 * @private
 * @param {Object} elem Default settings
 * @returns {boolean} TRUE if elem is a dom element; FALSE if it is a string or not does it have an innerHtml (not a dom element)
 */
const isDomElement = (elem) => {
	return !isString(elem) && isString((elem).innerHTML);
};

module.exports = isDomElement;