rp$.fn.emailAddress = function() {
	return this.each(function() {
		var ele = this;
		if(ele.tagName !== 'A' && ele.tagName !== 'SPAN') { return; }
		var isLink = (ele.tagName === 'A');
		var emailAddress = ele.firstChild;
		var emailRegex = /\[at\]/;
		var realAddress;
		if(!emailAddress) { return; }

		if (emailAddress.nodeName !== '#text' || !emailAddress.nodeValue.match(emailRegex)) {
			// Regex has NOT found [at] in nodeValue text between <a></a> tags
			if (ele.name.match(emailRegex)) {
				// Regex match on Name attribute of anchor tag
				// Replace text and create proper href
				realAddress = ele.name.replace('[at]', '@');
				if (isLink) {
					emailAddress.parentNode.setAttribute('href', 'mailto:' + realAddress);
				}
			}
		} else {
			// Replace text, create proper href and update nodeValue text between <a></a> tags
			realAddress = emailAddress.nodeValue.replace('[at]', '@');
			emailAddress.nodeValue = realAddress;
			if (isLink) {
				emailAddress.parentNode.setAttribute('href', 'mailto:' + realAddress);
			}
		}
	});
};
