module.exports = (window) => {
	return {
		redirect: (url) => {
			window.location = url;
		},
		reload: () => {
			window.location.reload();
		},
		getBase: () => {
			const {protocol, host} = window.location;
			return `${protocol}//${host}`;
		},
		get pathname() {
			return window.location.pathname;
		},
		get href() {
			return window.location.href;
		},
		get search() {
			return window.location.search;
		},
		get hash() {
			return window.location.hash;
		},
	};
};
