/* globals rp$, window, document, _satellite */

'use strict';
const get = require('lodash/get');
const debug = require('debug')('webstore:dtm');
const {
	trackPageViewEvent,
	trackPurchaseEvent,
	trackCheckoutEvent
} = require('public/js/lib/adobe-dtm-helper');

rp$(document).ready(function() {
	let retries = 0;

	function checkAdobeDTMLoaded() {
		let digitalData = window.digitalData || {};
		const digitalDataLoaded = get(digitalData, 'page');

		// Define the newEvent function on the first run-through of checkAdobeDTMLoaded function
		if (digitalDataLoaded && !get(digitalData, 'newEvent')) {
			window.digitalData.newEvent = handleNewEvent;
			trackPageViewEvent();
		}

		// Ensure the adobe script is properly loaded before the purchase event is fired
		if (rp$ && typeof _satellite !== 'undefined' && digitalDataLoaded) {

			// Fire the purchase event if we're on a completed order page for a new order
			if (get(digitalData, 'page.category.pageType') === 'order-confirmation'
				&& get(digitalData, 'transaction.trackTransaction')
				&& get(digitalData, 'transaction.orderTotal', 0) > 0
			) {
				trackPurchaseEvent();
				reportTransactionInternal();
			}

			// Prepare to send a purchase event if the cart flyover 'Checkout' button is pressed
			rp$('.cart-flyout .cart-listing').on('click', '.place-order-button', function() {
				trackCheckoutEvent();
			});

		} else if (retries < 15) {
			// Adobe DTM script not loaded yet, give more time for async script to load
			retries++;
			setTimeout(checkAdobeDTMLoaded, 250);
		}
	}

	checkAdobeDTMLoaded();
});

function handleNewEvent(ev) {
	var event_ = {
		type : (ev && ev.type) ? ev.type : null,
		eventName : (ev && ev.eventName) ? ev.eventName : null,
		eventAction : (ev && ev.eventAction) ? ev.eventAction : null,
		attributes : (ev && ev.attributes) ? ev.attributes : {},
		timeStamp : (ev && ev.timeStamp) ? ev.timeStamp : new Date()
	};

	// Event array is updated and a window.trigger is fired at this point to alert the browser that a new event has occurred.
	window.digitalData.events.push(event_);

	debug('eventTrigger', event_);
	debug('digitalDataObject', window.digitalData);

	rp$(window).trigger(event_);

	return event_;
}

/*
	DTB-678 Report digitalData object at the time a transaction is reported to Adobe DTM
	to help diagnose orders being reported to Adobe DTM with zero-dollar value
*/
function reportTransactionInternal() {
	let digitalData = get(window, 'digitalData') || {};

	$ajax({
		type: 'POST',
		url: '/ajax/checkout/report-adobe-dtm-transaction',
		dataType: 'json',
		data: {
			channel_order_number: get(digitalData, 'transaction.orderID'),
			digital_data: { // We don't want to send everything since there are some functions defined as part of digitalData
				products: get(digitalData, 'products'),
				transaction: get(digitalData, 'transaction')
			}
		}
	});
}
