var overlay = (function () {
	var id = 'spinner_overlay';

	return {
		id: id,
		sanitizedId: null,
		create: function (target, opt={}) {
			let spinner_content;
			let animationClasses;
			let wrapClass;
			const $ele = rp$(target);

			this.id = 'spinner_overlay';
			if ($ele.attr('id')) {
				this.id += '_'+ $ele.attr('id');
				// We don't control the body on plugin stores, so need to sanitize the id
				if (this.id.includes('/') && !this.sanitizedId) {
					this.sanitizedId = this.id.replaceAll('/','\\/');
				}
			} else {
				this.id += '_'+ Date.now();
			}

			/* Check to see if element already has a spinner running
			.*-----------------------------------------*/
			if ($ele.find('.spinner-wrap').length > 0) {
				// already spinning - no need to add another spinner
				return;
			}

			animationClasses = 'fa fa-refresh fa-spin';

			wrapClass = 'spinner-wrap';
			if (opt && opt.fixedPosition) {
				wrapClass += ' spinner-fixed';
			}

			spinner_content  = '<div id="'+ this.id +'" class="'+wrapClass+'">';
			spinner_content += '<div class="spinner-overlay"></div>';
			spinner_content += '<div class="spinner-icon"><i class="'+animationClasses+'"></i></div>';
			spinner_content += '</div> <!-- #'+ this.id + '-->';

			$ele.append(spinner_content);

			// Could change this to validate and apply any styles to different
			// parts of the spinner
			if (opt.animation.top) {
				$ele.find('.spinner-icon').css('top', opt.animation.top);
			}
		},
		show: function (callback) {
			const id = this.sanitizedId || this.id;
			rp$('#' + id).fadeIn('fast', callback);
		},
		destroy: function () {
			const id = this.sanitizedId || this.id;
			rp$(`#${id}`).fadeOut('fast', function () {
				rp$(`#${id}`).remove();
			});
		}
	};
})();

rp$.fn.overlaySpinner = function (options) {
	var opt = rp$.extend({
		// Defaults
		animation: {
			top: '50%'
		}
	}, options);

	overlay.create(this, opt);
	overlay.show(rp$.noop);

	return this;
};
rp$.fn.overlaySpinner.remove = function () {
	overlay.destroy();
	return this;
};
