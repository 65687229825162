/* global window */

const get = require('lodash/get');

function trackImpressionsEvent(impressions = null, findingMethod = null) {

	if (impressions && get(window, 'digitalData.products')) {
		window.digitalData.products.impressions = impressions;
	}

	if (findingMethod && window.digitalData) {
		window.digitalData.productFindingMethod = findingMethod;
	}

	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'impressions',
		eventAction: 'product'
	});
}

function trackCategoryListEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'categoryList',
		eventAction: 'product'
	});
}

function trackCheckoutEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'checkout',
		eventAction: 'cart'
	});
}

function trackDetailsViewEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'detailsView',
		eventAction: 'product'
	});
}

function trackPageViewEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'JSpageView',
		eventAction: 'contentView'
	});
}

function trackPurchaseEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'purchase',
		eventAction: 'cart'
	});
}

function trackCartRemovalEvent(eventAttributes) {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'cartRemove',
		eventAction: 'product',
		attributes: eventAttributes
	});
}

function trackClearCartEvent() {
	sendAdobeDTMEvent({
		type: 'CustomTagEvent',
		eventName: 'cartRemoveAll',
		eventAction: 'product'
	});
}

function sendAdobeDTMEvent(event) {
	if (get(window, 'digitalData.newEvent')) {
		window.digitalData.newEvent(event);
	}
}

module.exports = {
	trackImpressionsEvent,
	trackCheckoutEvent,
	trackCategoryListEvent,
	trackDetailsViewEvent,
	trackPageViewEvent,
	trackPurchaseEvent,
	trackCartRemovalEvent,
	trackClearCartEvent,
	sendAdobeDTMEvent
};
