/* global window, rp$, rp_app, paypal */

const get = require('lodash/get');

const RpPaypal = {};

function setupGlobalPaypalEnv({apiToken, paypalEnv}) {
	paypal.checkout.setup(apiToken, {
		environment: paypalEnv,
	});
	paypal.checkout.initXO();
}

RpPaypal.doPaypalCheckout = function (e) {
	e.preventDefault();
	const apiToken = get(rp_app, 'env.paypal.apiToken', false);
	const paypalEnv = get(rp_app, 'env.paypal.environment', false);
	if (apiToken && paypalEnv) {
		setupGlobalPaypalEnv({apiToken, paypalEnv});
	} else {
		rp$('body').overlaySpinner();
	}

	$ajax({
		method: 'POST',
		dataType: 'json',
		url: '/ajax/checkout/paypal',
		success: (response, status/*, xhr*/) => {
			if (response.redirect) {
				window.location = response.redirect;
				return false;
			}

			if (!apiToken || !paypalEnv) {
				setupGlobalPaypalEnv({
					apiToken: response.apiToken,
					paypalEnv: response.paypalEnv,
				});
				rp$('body').overlaySpinner.remove();
			}

			if (status === 'success') {
				paypal.checkout.startFlow(response.token);
			} else {
				paypal.checkout.closeFlow();
			}
		}
	});
};

module.exports = RpPaypal;