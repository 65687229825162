/* global window */
module.exports = (url, method = 'GET', data = {}) => {
	return new Promise((resolve, reject) => {
		window.rp_app.request({
			url,
			method,
			dataType: 'json',
			data: data,
			error: (error) => {
				reject(error);
			},
			success: (response/*, status, xhr*/) => {
				resolve(response);
			}
		});
	});
};
