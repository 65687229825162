'use strict';

require('core-js/fn/array/includes');
require('core-js/fn/object/assign');

const csrf = require('public/js/lib/csrf');

const AJAXRequest = (requestOpts) => {
	/* For guarded methods, handle CSRF
	.*-----------------------------------------*/
	if (['POST', 'PUT', 'DELETE', 'PATCH'].includes(requestOpts.method)) {

		/* Make sure CSRF token is added to the request
		.*-----------------------------------------*/
		requestOpts.data = requestOpts.data || {};
		const csrfItems = csrf.getCsrfRequestData(csrf.getToken());
		requestOpts.data = Object.assign(requestOpts.data, ...csrfItems);
	}

	/* Execute Request
	.*-----------------------------------------*/
	$ajax(requestOpts);
};

module.exports = (requestOpts) => {
	AJAXRequest(requestOpts);
};
