/* global window, rp_app */
const get = require('lodash/get');

const getSelectedLocale = () => {
	const locale = get(window, 'rp_app.env.selected_locale', 'en_US');
	return locale.replace('_', '-');
};

module.exports = {
	getSelectedLocale,
};