/* global rp$, rp_app */
'use strict';

const paypal = require('public/js/components/paypal');
const paypalSmartButtons = require('public/js/components/paypal-smart-buttons');

const initPaypalButtons = () => {
	rp$('.cart-listing').on('click', '.paypal-button', (e) => {
		paypal.doPaypalCheckout(e);
	});

	const paypalFlyoutContainerClass = '.paypal-smart-flyout-button';
	paypalSmartButtons.initSmartButtons(paypalFlyoutContainerClass, { 
		loadingCallback: (show) => {
			if (show) {
				rp$('body').overlaySpinner();
			} else {
				rp$('body').overlaySpinner.remove();
			}
		}
	});
};

module.exports = {
	initPaypalButtons,
};
