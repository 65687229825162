/********************************************
 * Notifications
 * -----------------------------------------
 * @section notifications
 ********************************************/
const notify = {};
notify.add_notification = function(message, type) {
	'use strict';
	var $notifications, $content;

	$notifications = rp$('#container_notification');
	$content = rp$('#container_notification .container-wrap');

	message = '<p class="message">'+ message +'</p>';
	$content.append(message).addClass(type);

	if(!$notifications.is(':visible')) {
		$notifications.slideDown('slow');
	}
};

notify.clear_notifications = function() {
	'use strict';
	var $notifications, $content;

	$notifications = rp$('#container_notification');
	$content = rp$('#container_notification .container-wrap');
	$notifications.slideUp('fast');
	$notifications.removeClass('error','info');
	$content.empty();
};

module.exports = notify;
