/* global rp$ */
const getOwlCarousel = () => import(/* webpackChunkName: "get-owl-carousel" */ 'owl.carousel');
const  handleOwlCarouselModule = ({elem, options}) => {
	//Only Add the carousel actions to the page if there is at least 1 add to carousel button.
	getOwlCarousel().then(() => {
		elem.owlCarousel(options);
	});
};

//This is just intercept calls to start a carousels, we will rerun these after the owl carousel is fully loaded.
//This will be replaced with the actual owl carousel once it loads.
rp$.fn.owlCarousel = function(options) {
	handleOwlCarouselModule({elem: this, options});
};
